
import Accordion from './Accordion';
import CustomCarousel from './CustomCarousel'; 
import { Helmet } from 'react-helmet';
import './smallkey.css';
import FareCard from './FareCard';
import GoogleAnalytics from './GoogleAnalytics';

function TempoTravellerOnRent(){

  const cardData = 
    {
      keyword: 'Tempo Traveller On Rent in Pune',
      heading: 'Amruta Travels: Tempo Traveller on Rent in Pune',
      headingDescription: 'Planning a group trip or family outing in Pune? Look no further than Amruta Travels for your Tempo Traveller rental needs. Our vehicles are equipped with a range of top-notch features designed to provide unparalleled comfort and convenience. Discover the top features that make our Tempo Travellers the best choice for your travel requirements in Pune.',
      topPlaces : [
        {
          title: "1. Spacious Interior",
          description: "Our mini buses are designed with a spacious interior to provide ample room for passengers. With comfortable seating arrangements and generous legroom, your journey will be pleasant and relaxed. Ideal for both short city trips and longer journeys, our mini buses ensure that everyone travels in comfort.",
        },
        {
          title: "2. Climate-Controlled Environment",
          description: "Traveling in Pune can be challenging due to fluctuating temperatures. Our mini buses come equipped with advanced air-conditioning systems to maintain a comfortable climate throughout your journey. Enjoy a cool and pleasant ride regardless of the weather outside.",
        },
        {
          title: "3. Modern Entertainment Systems",
          description: "To make your journey enjoyable, our mini buses are equipped with modern entertainment systems. Features like LED TVs, high-quality audio systems, and Bluetooth connectivity keep passengers entertained, making long trips more engaging and enjoyable.",
        },
        {
          title: "4. Ample Luggage Space",
          description: "Handling luggage can be a hassle, especially with a group. Our mini buses feature ample luggage compartments to accommodate all your belongings. Whether you’re carrying personal bags or larger items, there’s plenty of space to ensure everything is securely stored.",
        },
        {
          title: "5. Enhanced Safety Features",
          description: "Safety is a top priority at Amruta Travels. Our mini buses are equipped with modern safety features including seat belts for every passenger, emergency exits, fire extinguishers, and first-aid kits. These features ensure a safe and secure travel experience for all passengers.",
        },
        {
          title: "6. Professional and Experienced Drivers",
          description: "Our mini buses are driven by professional and experienced drivers who are well-versed in Pune’s routes and traffic conditions. Their expertise ensures punctuality and smooth navigation, allowing you to focus on your journey without any concerns.",
        },
        {
          title: "7. Well-Maintained Vehicles",
          description: "We take pride in maintaining our mini buses to the highest standards. Each vehicle undergoes regular servicing and thorough inspections to ensure it is in optimal condition. Cleanliness and hygiene are also top priorities, providing a pleasant environment for your journey.",
        },
        {
          title: "8. Flexible Rental Options",
          description: "At Amruta Travels, we understand that every trip is unique. Our mini bus rental services offer flexible options to suit your specific needs, whether you require the vehicle for a few hours, a day, or even longer. We can tailor our services to fit your itinerary and requirements.",
        },
        {
          title: "9. Competitive and Transparent Pricing",
          description: "We offer competitive pricing for our mini bus rentals, providing excellent value for your money. Our pricing structure is transparent with no hidden costs, ensuring that you know exactly what you’re paying for and can budget accordingly.",
        },
        {
          title: "10. Exceptional Customer Service",
          description: "From booking to the end of your journey, our customer service team is dedicated to providing exceptional support. We are here to assist you with any inquiries, address any concerns, and ensure a seamless and enjoyable travel experience.",
        }
      ],
      services : [
        {
          name: "Mini Bus on Rent in Hadapsar, Pune",
          description: "Perfect for Local Events and Gatherings. Whether you’re organizing a family event or a local corporate function, renting a mini bus in Hadapsar ensures convenience and comfort. Our mini buses are equipped with modern amenities to make your travel smooth and enjoyable."
        },
        {
          name: "Mini AC Bus on Rent in Wanowrie, Pune",
          description: "Stay Cool and Comfortable. For a pleasant journey in and around Wanowrie, opt for our air-conditioned mini buses. These buses are ideal for maintaining a comfortable temperature regardless of the weather, ensuring a refreshing travel experience."
        },
        {
          name: "Top Mini Bus on Rent in Kothrud, Pune",
          description: "Ideal for Family Outings and Corporate Events. Our top-rated mini buses in Kothrud offer spacious seating and advanced amenities, making them perfect for both family outings and corporate events. Enjoy a blend of comfort and convenience with our well-maintained fleet."
        },
        {
          name: "Mini Bus on Rent in Kharadi, Pune",
          description: "Convenient Travel for Business and Leisure. Rent a mini bus in Kharadi for seamless travel to meetings, events, or leisure activities. Our buses are designed to provide a comfortable ride, equipped with essential features for a smooth journey."
        },
        {
          name: "Mini Bus on Rent in Kalyani Nagar, Pune",
          description: "For Corporate and Social Gatherings. Kalyani Nagar residents can benefit from our mini bus rentals for various purposes, including corporate events and social gatherings. Enjoy reliable and comfortable transportation tailored to your needs."
        },
        {
          name: "Mini Bus on Rent in Shivaji Nagar, Pune",
          description: "Reliable Transportation for All Occasions. Whether it’s a family event or a group outing, our mini buses in Shivaji Nagar offer reliability and comfort. Choose from our range of options to suit your specific requirements."
        },
        {
          name: "Best Mini Bus on Rent in Baner, Pune",
          description: "Top-Notch Comfort and Amenities. Experience top-notch service with our mini bus rentals in Baner. Our vehicles are equipped with all the modern amenities to ensure a comfortable and enjoyable ride for any occasion."
        },
        {
          name: "Mini Bus on Rent for Corporate Events in Pune",
          description: "Professional and Efficient Travel. For corporate events, our mini buses provide a professional and efficient travel solution. Equipped with comfortable seating and essential amenities, they are perfect for transporting teams and clients."
        },
        {
          name: "Mini bus Hire for Wedding Party in Pimpri Chinchwad",
          description: "Celebrate in Style. Make your wedding celebrations memorable with our mini buses in Pimpri Chinchwad. Our buses offer comfort and style, ensuring a pleasant journey for your guests to and from the wedding venue."
        },
        {
          name: "Mini bus on Rent in Pune for Outstations",
          description: "Comfortable Tempo Traveller On Rent in Pune for Long Journeys. Travel to outstation destinations from Pune with ease in our well-equipped mini buses. Enjoy a comfortable ride with amenities designed for longer journeys."
        },
        {
          name: "Pune to Shirdi Mini Bus on Rent",
          description: "Spiritual Journeys Made Comfortable. Travel comfortably from Pune to Shirdi with our mini bus rentals. Our buses are equipped to ensure a smooth and serene journey for your pilgrimage or visit."
        },
        {
          name: "Tempo Traveller On Rent in Pune for Family",
          description: "Comfortable and Spacious for Family Trips. For family outings or reunions, our Tempo Traveller On Rent in Pune provide ample space and comfort. Enjoy a smooth and enjoyable trip with your loved ones in our well-maintained vehicles."
        },
        {
          name: "Mini Bus on Rent in Pune for Family with Driver",
          description: "Stress-Free Travel with Professional Drivers. Opt for our mini bus rentals with a professional driver for a hassle-free travel experience. Enjoy your family trip while we handle the driving and navigation."
        },
        {
          name: "Tempo Traveller On Rent in Pune Contact Number",
          description: "Contact Amruta Travels at +91 8421333435 for prompt and efficient tempo traveller on rent in Pune. We ensure a smooth and enjoyable ride for all our customers."
        }
      ],
      "tableData": [
        ['-Tempo Traveller from Pune to Mahabaleshwar', '-Pune to Mahabaleshwar Tempo Traveller Hire'],
        ['-Pune to Panchgani Tempo Traveller', '-Tempo Traveller for Rent in Pune'],
        ['-17-Seater Tempo Traveller for Mahabaleshwar Trip', '-17-Seater Tempo Traveller for Mahabaleshwar Tour Package'],
        ['-Pune Airport to Mahabaleshwar Tempo Traveller on Rent', '-Pune to Tapola Tempo Traveller on Rent'],
        ['-14-Seater Tempo Traveller for Maharashtra Trip', '-Tempo Traveller on Rent for Mahabaleshwar Outings'],
        ['-Mini Bus on Rent for Mahabaleshwar Trip', '-Tempo Traveller on Rent in Pune Rate Per Km']
      ],
      whychoose:[
        {
          WhyChooseheading: "Why Choose Amruta Travels for Pune to Mahabaleshwar Tempo Traveller?",
          WhyChoosedescription: "When it comes to traveling from Pune to Mahabaleshwar, Amruta Travels is your go-to provider for a comfortable and reliable journey. Whether you're heading for a leisure trip or an important event, our Tempo Traveller service ensures that you travel in style and comfort. Here's why Amruta Travels is the best choice for your trip:"
        },
        {
          WhyChooseheading: "Dependable Service:",
          WhyChoosedescription: "We pride ourselves on providing reliable and punctual service for your journey from Pune to Mahabaleshwar. You can count on us to adhere to your schedule and ensure a smooth travel experience."
        },
        {
          WhyChooseheading: "Comfortable Rides:",
          WhyChoosedescription: "Our well-maintained Tempo Travellers offer a smooth and enjoyable travel experience. With ample space and modern amenities, you can relax and enjoy the journey, whether it's a short or long trip."
        },
        {
          WhyChooseheading: "Professional Drivers:",
          WhyChoosedescription: "Our experienced drivers are dedicated to your safety and comfort. They are well-versed with the routes from Pune to Mahabaleshwar, ensuring a secure and pleasant journey."
        },
        {
          WhyChooseheading: "Competitive Pricing:",
          WhyChoosedescription: "We offer transparent and competitive rates with no hidden charges. Our pricing structure ensures that you get the best value for your money, making it easy to plan your travel expenses."
        },
        {
          WhyChooseheading: "Flexible Booking:",
          WhyChoosedescription: "With easy and flexible booking options, you can tailor your rental to fit your schedule and needs. Whether it's a day trip or a longer journey, we accommodate your requirements seamlessly."
        },
        {
          WhyChooseheading: "24/7 Customer Support:",
          WhyChoosedescription: "Our dedicated customer support team is available around the clock to assist with any inquiries or travel-related concerns. We are committed to providing a hassle-free experience from start to finish."
        },
        {
          WhyChooseheading: "Clean and Safe Vehicles:",
          WhyChoosedescription: "We ensure that our vehicles are regularly cleaned and maintained to the highest standards. Hygiene and safety are our priorities, so you can travel with peace of mind."
        }
      ]

    };

    
   
    const faqData = [
      {
        question: "How can I book a Tempo Traveller from Pune to Mahabaleshwar?",
        answer: "You can book a Tempo Traveller by calling us at +91 8421333435 or through our website. Our team will assist you with the booking process."
      },
      {
        question: "What types of Tempo Travellers are available for the Pune to Mahabaleshwar journey?",
        answer: "We offer a range of Tempo Travellers, including 17-seaters and mini buses, to accommodate different group sizes and preferences."
      },
      {
        question: "Are there any additional charges for the Tempo Traveller service?",
        answer: "Our pricing is transparent, with no hidden fees. Any additional charges for extra stops or extended mileage will be communicated in advance."
      },
      {
        question: "How do I know if the Tempo Traveller is well-maintained?",
        answer: "All our vehicles undergo regular inspections and maintenance to ensure they are in excellent condition for your safety and comfort."
      },
      {
        question: "Can I choose the driver for my trip?",
        answer: "While we assign experienced drivers to ensure a high-quality experience, you can let us know of any specific preferences when booking."
      },
      {
        question: "What if I need to change my booking?",
        answer: "Contact our customer support team to discuss any changes. We will accommodate your requests based on availability."
      },
      {
        question: "Is there a minimum rental period for the Tempo Traveller service?",
        answer: "We offer flexible rental periods to fit your needs, whether for a day trip or a longer journey."
      },
      {
        question: "What amenities are included in the Tempo Traveller service?",
        answer: "Our vehicles come with modern amenities such as air conditioning, comfortable seating, and entertainment options."
      },
      {
        question: "What is the cancellation policy for the Tempo Traveller service?",
        answer: "Cancellation policies may vary. Please contact us for details on our cancellation terms and any applibusle fees."
      },
      {
        question: "How can I contact Amruta Travels for support during my journey?",
        answer: "Reach our 24/7 customer support team at +91 8421333435 for any assistance during your trip."
      }
    ];
    

    const testimonials = [
      {
        name: "Miss Rasika Sharma",
        text: "I recently rented a Tempo Traveller from Amruta Travels for our trip to Mahabaleshwar, and it was a fantastic experience. The vehicle was clean, spacious, and well-maintained. The driver was professional and made our journey enjoyable. Highly recommend Amruta Travels for group trips!"
      },
      {
        name: "Mr. sunil Patel",
        text: "Our corporate team used Amruta Travels for a Mahabaleshwar outing, and we were thoroughly impressed. The Tempo Traveller was luxurious and comfortable, perfect for our needs. The booking process was smooth, and the driver was excellent. We’ll definitely choose Amruta Travels again for future trips."
      }
    ];
    
      
        const carouselItems = testimonials.map(testimonial => (
          <div key={testimonial.name}>
            <blockquote>
              <p>{testimonial.text}</p>
              <footer>— {testimonial.name}</footer>
            </blockquote>
          </div>
        ));
      
        const carouselOptions = {
          loop: true,
          margin: 10,
          nav: true,
          items: 1
        };
      
      const contactData = {
        heading: " Tempo Traveller On Rent In Pune Contact Number",
        contactNumbers: [
          "+91 8421333435",
          "+91 7875774499",
          "+91 7620411277",
          "+91 9284718314"
        ],
        email: "booking@amrutatravels.com"
      };


      const Images = [
        {
            place: "/img/tempo4.jpg",
            text: "Tempo Traveller Hire in Pune",
          },
          {
            place: "/img/mini5.jpg",
            text: "Mini Bus On Rent in Pune",
          },
        {
          place: "/img/bus32-2.jpg",
          text: "32 Seater Bus Rent in Pune",
        },
        {
          place: "/img/bus45-3.jpg",
          text: "45 Seater Bus on Rent in Pune",
        },
        {
            place: "/img/luxury6.jpg",
            text: "Luxury Bus Rental in Pune",
          },
          {
            place: "/img/force-urbania.jpg",
            text: "Force Urbania On Rent in Pune",
          },
          {
            place: "/img/mini5.jpg",
            text: "Bus Service for Picnic in Pune",
          },
          {
            place: "/img/tempo4.jpg",
            text: "17 Seater Tempo Traveller on Rent in Pune",
          },
          ];
     
    return(
        <div>
          <GoogleAnalytics/>

<Helmet>
    <title> Tempo Traveller On Rent in Pune ₹25 Per Km | Contact: +91 8421333435 | Amruta Travel | 17 Seater Tempo Traveller on Rent in Pune | Luxury & Affordable Options</title>
    <meta name="description" content="Book 17 Seater Tempo Traveller on Rent in Pune for outstation trips and local journeys. Comfortable, spacious, and ideal for group travel. Affordable pricing with luxury options available. Book online now!" />
    <meta name="keywords" content="Tempo Traveller in Pune, 17 Seater Bus on Rent in Pune Price, 17 Seater Tempo Traveller on Rent Pune, Traveller on Rent Pune, Traveller Bus Rental Pune, Tempo Traveller on Rent Pune to Outstation, Pune to Shirdi Tempo Traveller Rate, Tempo Traveller Hire Pune, Tempo Traveller Rental Pune, Pune to Mahabaleshwar Tempo Traveller, Pune to Goa Tempo Traveller on Rent, Luxury Tempo Traveller on Rent Pune" />

    {/* Open Graph Tags for Social Sharing */}
    <meta property="og:title" content="Contact: +91 8421333435 | Amruta Travel |17 Seater Tempo Traveller on Rent in Pune | Luxury & Affordable" />
    <meta property="og:description" content="Hire a 17 Seater Tempo Traveller in Pune for outstation trips and local travel. Spacious, comfortable, and affordable group travel options. Book your trip now!" />
    <meta property="og:url" content="https://amrutatravel.com/tempo-traveller-on-rent-in-pune" />
    <meta property="og:image" content="https://amrutatravel.com/images/tempo-traveller-rent-pune.jpg" />

    {/* Twitter Card Tags */}
    <meta name="twitter:card" content="summary_large_image" />
    <meta name="twitter:title" content="Contact: +91 8421333435 | 17 Seater Tempo Traveller on Rent in Pune | Luxury & Affordable" />
    <meta name="twitter:description" content="Book a 17 Seater Tempo Traveller in Pune for your group travel needs. Affordable and comfortable with both luxury and standard options. Reserve now!" />
    <meta name="twitter:image" content="https://amrutatravel.com/images/tempo-traveller-rent-pune.jpg" />

    {/* Structured Data Markup for Local Business */}
    <script type="application/ld+json">
        {`
            {
                "@context": "https://schema.org",
                "@type": "LocalBusiness",
                "name": "Amruta Travels",
                "description": "Affordable 17 Seater Tempo Traveller Rentals in Pune. Ideal for group travel, including outstation and local trips. Book online for the best rates.",
                "url": "https://amrutatravel.com/tempo-traveller-on-rent-in-pune",
                "logo": "https://amrutatravel.com/images/logo.png",
                "image": "https://amrutatravel.com/images/tempo-traveller-rent-pune.jpg",
                "telephone": "+91 8421333435",
                "contactPoint": {
                    "@type": "ContactPoint",
                    "telephone": "+91 8421333435",
                    "contactType": "Customer Service",
                    "areaServed": "IN",
                    "availableLanguage": ["English", "Hindi", "Marathi"]
                },
                "address": {
                    "@type": "PostalAddress",
                    "streetAddress": "Address of the rental service",
                    "addressLocality": "Pune",
                    "addressRegion": "Maharashtra",
                    "postalCode": "411001",
                    "addressCountry": "IN"
                },
                "geo": {
                    "@type": "GeoCoordinates",
                    "latitude": "18.5204",
                    "longitude": "73.8567"
                },
                aggregateRating: {
                    "@type": "AggregateRating",
                    "ratingValue": "4.7",
                    "reviewCount": "180"
                },
                "sameAs": [
                    "https://https://www.facebook.com/people/Amruta-travels/61567052876581/?mibextid=LQQJ4d&rdid=ik0ebd0HKUH6Pi4Z&share_url=https%3A%2F%2Fwww.facebook.com%2Fshare%2F1ApFoorKKj%2F%3Fmibextid%3DLQQJ4d/yourbusiness",
                    "https://https://www.instagram.com/amruta_travels_//yourbusiness"
                ],
                "keywords": [
                    "Tempo Traveller in Pune",
                    "17 Seater Bus Rental Pune",
                    "Luxury Tempo Traveller Pune",
                    "Outstation Tempo Traveller Rental",
                    "Tempo Traveller Pune to Shirdi",
                    "Pune to Mahabaleshwar Tempo Traveller",
                    "Pune to Goa Tempo Traveller Rental"
                ]
            }
        `}
    </script>
</Helmet>


<section className="page-header">
      <div className="page-header-shape"></div>
      <div className="container">
        <div className="page-header-info">
          <h4>{cardData.keyword}</h4>
          <h2>
            Feel your journey <br /> with <span>Amruta Travels</span>
          </h2>
          
        </div>
      </div>
    </section>

<section>
    <div className="container-fluid ">
<div className="row">
<div className="col-12 col-md-7">
    <img src='\img\Keyword Based Image\Keyword Based Image-01.jpg' alt='img'/>
<h1 className="py-1">{cardData.heading} </h1><p>{cardData.headingDescription}</p>
<div>
      {cardData.topPlaces.map((place, index) => (
        <div key={index}>
          <h3 className="py-1 Colo">{place.title}</h3>
          <p>{place.description}</p>
        </div>
      ))}
    </div>
    <div>
        {cardData.services.map((service, index) => (
          <div key={index} className=" my-4">
            <h3 className="py-1 Colo">{service.name}</h3>
            <p>{service.description}</p>
          </div>
        ))}
      </div>

<table className="table table-responsive Border-key">
  <tbody className=' Border-key'>
    {cardData.tableData.map((row, rowIndex) => (
      <tr className='Border-key' key={rowIndex}>
        {row.map((cell, cellIndex) => (
          <td key={cellIndex} className=' Border-key' >{cell}</td>
        ))}
      </tr>
    ))}
  </tbody>
</table>

   <div id="why-choose-section">
      {cardData.whychoose.map((item, index) => (
        <div key={index}>
          <h3 className="py-1 Colo">{item.WhyChooseheading}</h3>
          <p>{item.WhyChoosedescription}</p>
        </div>
      ))}
    </div>

   

<div>
      <h3 className='Colo'>FAQs About Pune to Mahabaleshwar Tempo Traveller for Amruta Travels</h3>
      <Accordion data={faqData} />
    </div>

    <CustomCarousel items={carouselItems} options={carouselOptions} />

    <div>

      <div className="contact-box">
      <h2>{contactData.heading}</h2>
      <p>For booking inquiries or any assistance, please feel free to contact us:</p>
      <div className="contact-details">
        <p><strong className='Colo'>Mobile No:</strong></p>
        <ul>
          {contactData.contactNumbers.map((number, index) => (
            <li key={index}>
              <a href={`tel:${number}`} className="contact-link">
                {number}
              </a>
            </li>
          ))}
        </ul>
        <p><strong className='Colo'>Email Id:</strong></p>
        <p>
          <a href={`mailto:${contactData.email}`} className="contact-link">
            {contactData.email}
          </a>
        </p>
      </div>
    </div>
    </div>
</div>

<div className='col-12 col-md-4' >
              {Images.map((e) => {
                return (
                  <div className="box1">
                    <a
                      href="./"
                      className="d-flex justify-content-around align-items-center"
                    >
                      <div className="b1">
                        <img src={e.place} alt="img" />
                      </div>
                      <div className="b2">
                        {" "}
                        <a href="./">{e.text}</a>
                      </div>
                    </a>
                  </div>
                );
              })}

<div className=" rounded p-4 shadow-sm Uni_border" >
    <h4 className="pb-3 pt-3 lead fw-bold whit">Contact Information</h4>

    <div className="row ">
   
      <div className="col-12 mb-3">
        <div className="Small_border  rounded p-3 text-center ">
          <h4 className=" lead fw-semibold text-dark">Phone Numbers</h4>
          <i className="bi bi-telephone-fill fs-1 mb-2"></i>
          <div className=''>
            <a href="tel:+91 8421333435" className="d-block  whit">+91 8421333435</a>
            <a href="tel:+91 7875774499" className="d-block  whit">+91 7875774499</a>
            <a href="tel:+91 7620411277" className="d-block  whit">+91 7620411277</a>
            <a href="tel:+91 9284718314" className="d-block  whit">+91 9284718314</a>
          </div>
        </div>
      </div>

    
      <div className="col-12 mb-3 ">
        <div className="Small_border rounded p-3 text-center">
          <i className="bi bi-envelope fs-1 mb-2"></i>
          <div>
            <h4 className=" fw-semibold lead">Email</h4>
            <a href="mailto:booking@amrutatravel.com" className="whit d-block">
              booking@amrutatravel.com
            </a>
          </div>
        </div>
      </div>

      
      <div className="col-12 mb-3">
        <div className="Small_border  rounded p-3 text-center">
          <i className="bi bi-house-fill fs-1 mb-2"></i>
          <div>
            <h4 className=" fw-semibold lead">Address</h4>
            <p className="whit ">
              <i>
              SSKTravels24:<br />
              Office No.- 2, Ground Floor,<br />
              Wings H-(Puram), Prayeja City,<br />
              Phase-2, Near Prayeja City,<br />
              Vadgaon Budruk,<br />
              Pune, Maharashtra - 411041
              </i>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>


  
</div>
<FareCard/>

</div>
    </div>
</section>



        </div>
    );
}
export default TempoTravellerOnRent;