
import Accordion from './Accordion';
import CustomCarousel from './CustomCarousel'; 
import { Helmet } from 'react-helmet';
import './smallkey.css';
import FareCard from './FareCard';
import GoogleAnalytics from './GoogleAnalytics';

function  MiniBus(){

  const cardData = 
    {
      keyword: ' Mini Bus on Rent in Pune ',
      heading: 'Amruta Travels : Mini Bus on Rent in Pune ',

      headingDescription: ' Amruta Travels stands out with its exceptional mini bus rental service. Our mini buses are designed to offer unmatched comfort and convenience for any journey, whether it’s a family outing, corporate event, or special occasion. Discover the top features of our mini buses that make Amruta Travels your ideal choice for mini bus rental in Pune.',

      top:"Top Features of Our Tempo Travellers: ",
       topPlaces : [
        {
          title: "1. Spacious and Comfortable Interiors",
          description: "Our mini buses boast spacious interiors that ensure ample legroom and headroom for all passengers. Designed with comfort in mind, the seating arrangement allows everyone to travel without feeling cramped, making long journeys and short trips equally enjoyable."
        },
        {
          title: "2. Luxurious Seating Options",
          description: "Travel in style with our plush, well-cushioned seats. Each seat is ergonomically designed with adjustable headrests and individual armrests, providing a relaxing travel experience. Whether you’re embarking on a business trip or a family excursion, our seats offer the ultimate in comfort."
        },
        {
          title: "3. Advanced Air Conditioning",
          description: "Beat the Pune heat with our state-of-the-art air conditioning systems. Our mini buses are equipped with powerful AC units that keep the cabin cool and comfortable regardless of the weather outside. Enjoy a pleasant and refreshing ride throughout your journey."
        },
        {
          title: "4. Ample Luggage Space",
          description: "Packing for a trip is made easy with our mini buses' generous luggage compartments. With ample space for your bags and belongings, you don’t have to worry about leaving anything behind. Our mini buses ensure that your luggage is securely stored while maximizing passenger comfort."
        },
        {
          title: "5. Modern Entertainment Systems",
          description: "Enhance your travel experience with our modern entertainment features. Each mini bus comes equipped with high-quality music systems and LED screens. Whether you’re enjoying your favorite playlist or watching a movie, our entertainment options make the journey more enjoyable."
        },
        {
          title: "6. Professional and Experienced Drivers",
          description: "Safety and professionalism are our top priorities. Our experienced drivers are not only skilled in navigating Pune’s roads but also well-trained in providing excellent customer service. Sit back and relax as our drivers ensure a smooth and secure journey."
        },
        {
          title: "7. Well-Maintained Vehicles",
          description: "At Amruta Travels, we pride ourselves on the quality of our vehicles. Our mini buses are regularly serviced and maintained to ensure they meet the highest standards of safety and cleanliness. You can trust that our well-maintained vehicles will provide a reliable and comfortable ride."
        },
        {
          title: "8. Flexible Rental Packages",
          description: "We offer flexible rental packages tailored to your specific needs. Whether you require a mini bus for a few hours or several days, our rental options are designed to accommodate various schedules and budgets. Enjoy the flexibility to choose a rental plan that works best for you."
        },
        {
          title: "9. Competitive and Transparent Pricing",
          description: "Get the best value for your money with our competitive pricing. We offer clear and upfront rates with no hidden charges, allowing you to plan your budget with confidence. Our transparent pricing ensures you receive high-quality service without any surprises."
        },
        {
          title: "10. 24/7 Customer Support",
          description: "Our commitment to customer satisfaction is reflected in our round-the-clock customer support. Whether you need assistance with booking, have inquiries, or require support during your journey, our dedicated team is available at all times to ensure a seamless experience."
        }
      ],
      
     "services": [
    {
      "name": "Mini Bus on Rent in Pune",
      "description": "Looking for a reliable mini bus on rent in Pune? Whether you're planning a family outing, a corporate event, or a weekend getaway, Amruta Travels offers an exceptional range of mini bus rental services tailored to meet your needs. Discover how our mini buses can make your travel experience seamless and enjoyable."
    },
    {
      "name": "Mini Bus Hire in Pune",
      "description": "Our mini bus hire services in Pune are designed to provide you with the utmost convenience and comfort. Whether you need a vehicle for a day trip or an extended rental, we offer a diverse fleet of well-maintained mini buses that cater to all group sizes and travel requirements."
    },
    {
      "name": "Minibus Hire with Driver for Outstation",
      "description": "Planning a trip out of Pune? Our minibus hire with driver for outstation ensures that you can travel comfortably and safely to destinations beyond the city limits. Our professional drivers are experienced in navigating routes to popular outstation spots, providing you with a stress-free journey."
    },
    {
      "name": "Mini Bus Rentals for Corporate Trips",
      "description": "Elevate your corporate travel experience with our mini bus rentals for corporate trips. We offer spacious and well-equipped mini buses that are perfect for business meetings, team outings, and client visits. Enjoy amenities like comfortable seating and modern entertainment systems while traveling with your colleagues."
    },
    {
      "name": "Small Bus Rental Service in Pune",
      "description": "For those looking for a smaller, more intimate travel option, our small bus rental service in Pune is the perfect choice. Ideal for family gatherings, social events, or small corporate teams, our small buses offer a cozy and convenient travel experience."
    },
    {
      "name": "Minibus Rental Near Me in Pune",
      "description": "Searching for a minibus rental near you in Pune? Amruta Travels provides easy access to minibus rentals across various locations in Pune. Simply contact us to find a convenient pickup point and get started on your journey with minimal hassle."
    },
    {
      "name": "Mini Bus Rent Per Km",
      "description": "Our mini bus rent per km pricing model ensures that you only pay for the distance you travel. This flexible pricing structure allows you to budget effectively while enjoying a comfortable ride. Whether it's a short trip or a long-distance journey, our transparent rates make planning your travel expenses straightforward."
    },
    {
      "name": "Mini Coach Hire in Pune",
      "description": "For a more luxurious and spacious travel option, consider our mini coach hire services in Pune. Our mini coaches are equipped with premium features, making them perfect for longer trips, special events, or occasions requiring extra comfort and amenities."
    },
    {
      "name": "Mini Bus for Rent Near Me in Pune",
      "description": "If you're looking for a mini bus for rent near you in Pune, Amruta Travels has got you covered. With various pickup locations across the city, we make it easy to find a convenient and accessible rental option for your travel needs."
    },
    {
      "name": "12-Seater Mini Bus on Rent in Pune",
      "description": "Our 12-seater mini buses are ideal for small groups, family outings, or intimate corporate gatherings. Enjoy the comfort of well-cushioned seats, ample legroom, and modern amenities that make every trip enjoyable. Perfect for local travel or short outstation trips."
    },
    {
      "name": "Mini Bus on Rent Near Me for Outstation",
      "description": "Heading out of Pune? Our mini bus on rent near you for outstation travel offers the perfect solution for comfortable and convenient journeys to destinations outside the city. With a focus on safety and reliability, our service ensures a smooth trip no matter the distance."
    },
    {
      "name": "Mini Bus for Lonavala Picnic from Pune",
      "description": "Planning a picnic to Lonavala? Our mini buses are the perfect choice for a fun and comfortable trip to this popular destination. With plenty of space for passengers and their picnic essentials, our mini buses make traveling to Lonavala a breeze."
    },
    {
      "name": "Mini Bus Hire for Event in Pune",
      "description": "Planning a special event in Pune? Our mini bus hire for events ensures that your guests travel comfortably and arrive on time. Whether it's a corporate function, a birthday party, or a social gathering, our mini buses provide ample space and comfort for all attendees, making your event a success."
    },
    {
      "name": "Pune to Goa Mini Bus",
      "description": "Traveling from Pune to Goa? Our mini bus rental service for the Pune to Goa route offers a comfortable and convenient way to reach your destination. Enjoy the scenic drive with spacious seating, air conditioning, and entertainment options, ensuring a pleasant journey to the beautiful beaches of Goa."
    },
    {
      "name": "Mini Bus Hire in Pimpri Chinchwad",
      "description": "If you're in Pimpri Chinchwad and need a mini bus rental, Amruta Travels has you covered. We offer mini bus hire services in Pimpri Chinchwad that cater to various travel needs, from local trips to outstation journeys. Our well-maintained vehicles and professional drivers ensure a reliable and comfortable experience."
    },
    {
      "name": "Mini Bus Hire in Kharadi Pune",
      "description": "Located in Kharadi? Our mini bus hire service in Kharadi Pune is perfect for both local and outstation travel. Whether you’re planning a family trip, a corporate event, or a group outing, our mini buses provide the ideal solution with modern amenities and spacious interiors."
    },
    {
      "name": "Minibus Hire for Wedding Party in Pune",
      "description": "Make your wedding day extra special with our minibus hire for wedding parties in Pune. Our mini buses offer stylish and comfortable transportation for you and your guests. With features like plush seating and ample space for wedding attire and accessories, we ensure that your big day is memorable for all the right reasons."
    },
    {
      "name": "Mini Bus on Rent in Hinjewadi",
      "description": "For those in Hinjewadi, our mini bus on rent offers a convenient travel solution for business or leisure. Whether you need transportation for a corporate event or a family outing, our mini buses are equipped to handle various requirements with ease and comfort."
    },
    {
      "name": "Mini Bus on Rent in Baner",
      "description": "If you're in Baner and looking for a mini bus rental, Amruta Travels provides reliable and flexible options to suit your needs. From local trips around Baner to extended journeys, our mini buses offer the perfect blend of comfort and functionality for all types of travel."
    },
    {
      "name": "Mini Bus on Rent in Pune Near Me",
      "description": "Searching for a mini bus on rent in Pune near you? Our service is designed to provide convenient and accessible rental options across Pune. With multiple pickup locations, finding a mini bus close to your location has never been easier."
    },
    {
      "name": "Luxury Mini Bus on Rent in Pune Near Me",
      "description": "For those seeking a touch of luxury, our luxury mini bus on rent in Pune provides an upscale travel experience. Featuring plush interiors, advanced amenities, and top-notch comfort, our luxury mini buses are ideal for special occasions, high-profile events, and premium travel experiences."
    },
    {
      "name": "Mini Bus on Rent in Pune for Family",
      "description": "Looking for a mini bus on rent in Pune for a family outing? Our family-friendly mini buses are designed to offer ample space and comfort, making family trips enjoyable and stress-free. With features like comfortable seating and ample luggage space, we cater to all your family’s travel needs."
    },
    {
      "name": "13, 15, 17, 20, 24, 27 Seater Mini Bus on Rent in Pune",
      "description": "Whether you need a 13-seater mini bus or a 27-seater vehicle, Amruta Travels has a range of mini buses available for rent in Pune. Our diverse fleet ensures that you can find the perfect bus size for your group, ensuring comfort and convenience for any size of travel party."
    },
    {
      "name": "Mini Bus on Rent in Pune Contact Number",
      "description": "Contact Amruta Travels at +91 8421333435, +91 7875774499, +91 7620411277, +91 9284718314 for prompt and reliable mini bus rental services in Pune. We ensure a smooth and enjoyable ride for all our customers."
    }
  ],
      "tableData": [
    ["-Mini bus on rent", "-Mini bus hire in Pune"],
    ["-Minibus hire with driver for outstation", "-Mini bus rentals for Corporate Trip"],
    ["-Small bus rental service in Pune", "-Minibus rental near me in Pune"],
    ["-Mini Bus Rent Per Km", "-Mini coach hire in Pune"],
    ["-Mini bus for rent near me in Pune", "-12-seater mini bus on rent in Pune"],
    ["-Mini bus on rent near me for Outstation", "-Mini bus for Lonavala Picnic from Pune"],
    ["-Mini bus Hire for event in Pune", "-Pune to Goa mini bus"],
    ["-Mini bus hire in Pimpri Chinchwad", "-Mini bus hire in Kharadi Pune"],
    ["-Minibus Hire for Wedding party in Pune", "-Mini Bus On Rent in Hinjewadi"],
    ["-Mini bus on rent in Baner", "-Mini bus on rent in Pune near me"],
    ["-Luxury mini bus on rent in Pune near me", "-Mini bus on rent in Pune for family"],
    ["-13, 15, 17, 20, 24, 27 Seater Mini Bus on Rent in Pune", ""]
  ],
     "whychoose": [
    {
      "WhyChooseheading": "Why Choose Amruta Travels for Mini Bus on Rent in Pune?",
      
    },
    {
      "WhyChooseheading": "Diverse Fleet of Mini Buses:",
      "WhyChoosedescription": "At Amruta Travels, we offer a wide selection of mini buses to meet various travel needs. Whether you need a 12-seater for a small family trip or a 27-seater for a large group event, our fleet has the right vehicle for you. Each bus is equipped with modern amenities to ensure a comfortable journey."
    },
    {
      "WhyChooseheading": "Exceptional Comfort and Luxury:",
      "WhyChoosedescription": "Our mini buses are designed with your comfort in mind. From plush seating and ample legroom to advanced air conditioning and entertainment systems, we ensure that every journey is pleasant and enjoyable. For those seeking an extra touch of elegance, we offer luxury mini buses with high-end features."
    },
    {
      "WhyChooseheading": "Flexible Rental Options:",
      "WhyChoosedescription": "We understand that different trips require different arrangements. That’s why we offer flexible rental options, including hourly, daily, and long-term rentals. Whether you’re planning a short city trip or an extended outstation journey, we have rental packages tailored to your needs."
    },
    {
      "WhyChooseheading": "Professional and Experienced Drivers:",
      "WhyChoosedescription": "Safety is a top priority at Amruta Travels. Our professional drivers are experienced, well-trained, and familiar with Pune’s routes and beyond. They ensure a smooth and secure travel experience, allowing you to sit back and relax."
    },
    {
      "WhyChooseheading": "Competitive and Transparent Pricing:",
      "WhyChoosedescription": "Enjoy transparent pricing with no hidden fees. At Amruta Travels, we offer competitive rates for mini bus rentals, ensuring you get the best value for your money. Our clear pricing structure helps you plan your travel budget with confidence."
    },
    {
      "WhyChooseheading": "Convenient Booking Process:",
      "WhyChoosedescription": "Booking a mini bus with us is simple and straightforward. Our user-friendly online booking system and dedicated customer support team make it easy to reserve your vehicle and address any questions or concerns you may have."
    },
    {
      "WhyChooseheading": "Well-Maintained and Clean Vehicles:",
      "WhyChoosedescription": "All our mini buses are regularly serviced and thoroughly cleaned to meet high standards of hygiene and safety. We take pride in maintaining our vehicles in top condition, providing you with a reliable and pleasant travel experience."
    },
    {
      "WhyChooseheading": "24/7 Customer Support:",
      "WhyChoosedescription": "Our commitment to excellent service extends beyond just providing a vehicle. Our 24/7 customer support team is always available to assist with bookings, modifications, or any issues you may encounter during your journey."
    },
    {
      "WhyChooseheading": "Tailored Solutions for Special Occasions:",
      "WhyChoosedescription": "Whether it’s a corporate event, a wedding, or a family outing, we offer customized mini bus rental solutions to make your special occasion memorable. Our vehicles and services are designed to accommodate the specific requirements of various events."
    },
    {
      "WhyChooseheading": "Trusted by Many:",
      "WhyChoosedescription": "With a reputation for reliability and quality, Amruta Travels is a trusted choice for mini bus rentals in Pune. Our satisfied customers and positive reviews speak volumes about our commitment to delivering exceptional travel experiences."
    }
  ]
    
    };

    
    
    const faqData = [
        {
          "question": "How do I book a mini bus with Amruta Travels?",
          "answer": "You can book a mini bus by contacting us directly via phone or email, or by using our easy-to-navigate online booking system. Our customer support team will assist you throughout the booking process."
        },
        {
          "question": "What types of mini buses are available for rent?",
          "answer": "We offer a range of mini buses, including 12-seaters, 15-seaters, 17-seaters, 20-seaters, 24-seaters, and 27-seaters. We also provide luxury options with advanced features for a premium experience."
        },
        {
          "question": "Are the mini buses equipped with air conditioning?",
          "answer": "Yes, all our mini buses come equipped with advanced air conditioning systems to ensure a comfortable environment, regardless of the weather outside."
        },
        {
          "question": "Can I hire a mini bus for an outstation trip?",
          "answer": "Absolutely. We offer mini bus rentals for outstation trips, including popular destinations like Goa, Lonavala, and Mahabaleshwar. Our drivers are well-versed with routes to ensure a smooth journey."
        },
        {
          "question": "How much does it cost to rent a mini bus?",
          "answer": "The cost of renting a mini bus varies depending on the type of vehicle, rental duration, and distance traveled. We provide transparent pricing with no hidden charges. Contact us for a detailed quote based on your specific requirements."
        },
        {
          "question": "What is included in the rental price?",
          "answer": "Our rental price typically includes the use of the mini bus, driver services, and basic amenities. Additional costs may apply for extra services such as extended mileage or special requests. We’ll provide a clear breakdown of costs when you book."
        },
        {
          "question": "Are there any additional charges I should be aware of?",
          "answer": "We strive to maintain transparent pricing. Any additional charges, such as for extra stops or extended travel beyond the agreed mileage, will be communicated to you upfront."
        },
        {
          "question": "Can I choose the driver for my trip?",
          "answer": "We assign experienced and professional drivers to ensure the highest quality service. While you can request specific preferences or requirements, we ensure that all our drivers meet our stringent standards of professionalism and safety."
        },
        {
          "question": "What should I do if I need to cancel or modify my booking?",
          "answer": "If you need to cancel or modify your booking, please contact our customer support team as soon as possible. We will assist you with the changes and inform you of any applicable cancellation or modification policies."
        },
        {
          "question": "How can I contact Amruta Travels for support during my journey?",
          "answer": "You can reach our 24/7 customer support team by calling us or sending an email. We are here to assist you with any queries or issues that may arise during your journey to ensure a smooth and enjoyable experience."
        }
      ];
      

      const testimonials = [
        {
          "name": "Mr. Rajiv Kumar",
          "text": "We recently hired a mini bus from Amruta Travels for a family reunion trip in Pune, and the experience was fantastic. The booking process was smooth, and the vehicle was delivered on time, clean, and well-maintained. The spacious interior and comfortable seating made our journey very enjoyable. The driver was courteous and professional, ensuring a safe and pleasant ride. We highly recommend Amruta Travels for anyone in need of reliable and comfortable mini bus rental services in Pune."
        },
        {
          "name": "Ms. Anjali Mehta",
          "text": "Our corporate team required a mini bus for an off-site meeting, and Amruta Travels exceeded our expectations. The mini bus was perfect for our group, offering ample space and modern amenities. The driver was punctual and well-informed about the best routes, which made our trip seamless. The service was top-notch from start to finish, with competitive pricing and excellent customer support. We’ll definitely choose Amruta Travels for our future group transportation needs in Pune."
        }
      ];
      
      
        const carouselItems = testimonials.map(testimonial => (
          <div key={testimonial.name}>
            <blockquote>
              <p>{testimonial.text}</p>
              <footer>— {testimonial.name}</footer>
            </blockquote>
          </div>
        ));
      
        const carouselOptions = {
          loop: true,
          margin: 10,
          nav: true,
          items: 1
        };
      
      const contactData = {
        heading: " Mini Bus On Rent in Pune Contact Number: ",
        contactNumbers: [
          "+91 8421333435",
          "+91 7875774499",
          "+91 7620411277",
          "+91 9284718314"
        ],
        email: "booking@amrutatravels.com"
      };


      const Images = [
        {
            place: "/img/tempo4.jpg",
            text: "Tempo Traveller Hire in Pune",
          },
          {
            place: "/img/mini5.jpg",
            text: "Mini Bus On Rent in Pune",
          },
        {
          place: "/img/bus32-2.jpg",
          text: "32 Seater Bus Rent in Pune",
        },
        {
          place: "/img/bus45-3.jpg",
          text: "45 Seater Bus on Rent in Pune",
        },
        {
            place: "/img/luxury6.jpg",
            text: "Luxury Bus Rental in Pune",
          },
          {
            place: "/img/force-urbania.jpg",
            text: "Force Urbania On Rent in Pune",
          },
          {
            place: "/img/mini5.jpg",
            text: "Bus Service for Picnic in Pune",
          },
          {
            place: "/img/tempo4.jpg",
            text: "17 Seater Tempo Traveller on Rent in Pune",
          },
          ];
     
    return(
        <div>
          <GoogleAnalytics/>
<Helmet>
  <title>Contact: +91 8421333435 | Amruta Travel | Mini Bus on Rent in Pune | Affordable Mini Coach Rental for Your Trip</title>
  <script type="application/ld+json">
    {`
      {
        "@context": "https://schema.org",
        "@type": "LocalBusiness",
        "name": "Amruta Travels",
        "description": "Rent Mini Buses in Pune for Corporate Trips, Weddings, Outstation Travel, Family Tours, and More. Choose from 12 to 27 Seater Luxury Mini Coaches, available for local and outstation trips.",
        "address": {
          "@type": "PostalAddress",
          "streetAddress": "Shivaji Nagar, Pune",
          "addressLocality": "Pune",
          "addressRegion": "Maharashtra",
          "postalCode": "411005",
          "addressCountry": "IN"
        },
        "telephone": "+91-8421333435",
        "url": "https://amrutatravel.com/mini-bus-on-rent-pune",
        "logo": "https://amrutatravel.com/images/mini-bus-on-rent-pune.jpg",
        "image": "https://amrutatravel.com/images/mini-bus-on-rent-pune.jpg",
        "priceRange": "₹8000 - ₹30000",
        "offers": {
          "@type": "Offer",
          "url": "https://amrutatravel.com/mini-bus-on-rent-pune",
          "priceCurrency": "INR",
          "price": "8000",
          "priceValidUntil": "2024-12-31"
        }
      }
    `}
  </script>
</Helmet>


<section className="page-header">
      <div className="page-header-shape"></div>
      <div className="container">
        <div className="page-header-info">
          <h4>{cardData.keyword}</h4>
          <h2>
            Feel your journey <br /> with <span>Amruta Travels</span>
          </h2>
          
        </div>
      </div>
    </section>

<section>
    <div className="container-fluid ">
<div className="row">
<div className="col-12 col-md-7">
    <img src='\img\Keyword Based Image\Keyword Based Image-04.jpg' alt='img'/>
<h1 className="py-1">{cardData.heading} </h1><p>{cardData.headingDescription}</p>
<div>
      {cardData.topPlaces.map((place, index) => (
        <div key={index}>
          <h3 className="py-1 Colo">{place.title}</h3>
          <p>{place.description}</p>
        </div>
      ))}
    </div>
    <div>
        {cardData.services.map((service, index) => (
          <div key={index} className=" my-4">
            <h3 className="py-1 Colo">{service.name}</h3>
            <p>{service.description}</p>
          </div>
        ))}
      </div>

<table className="table table-responsive Border-key">
  <tbody className=' Border-key'>
    {cardData.tableData.map((row, rowIndex) => (
      <tr className='Border-key' key={rowIndex}>
        {row.map((cell, cellIndex) => (
          <td key={cellIndex} className=' Border-key' >{cell}</td>
        ))}
      </tr>
    ))}
  </tbody>
</table>

   <div id="why-choose-section">
      {cardData.whychoose.map((item, index) => (
        <div key={index}>
          <h3 className="py-1 Colo">{item.WhyChooseheading}</h3>
          <p>{item.WhyChoosedescription}</p>
        </div>
      ))}
    </div>

   
<div>
      <h3 className='Colo'>FAQs About Mini Bus On Rent in Pune with Amruta Travels:</h3>
      <Accordion data={faqData} />
    </div>

    <CustomCarousel items={carouselItems} options={carouselOptions} />

    <div>

      <div className="contact-box">
      <h2>{contactData.heading}</h2>
      <p>For booking inquiries or any assistance, please feel free to contact us:</p>
      <div className="contact-details">
        <p><strong className='Colo'>Mobile No:</strong></p>
        <ul>
          {contactData.contactNumbers.map((number, index) => (
            <li key={index}>
              <a href={`tel:${number}`} className="contact-link">
                {number}
              </a>
            </li>
          ))}
        </ul>
        <p><strong className='Colo'>Email Id:</strong></p>
        <p>
          <a href={`mailto:${contactData.email}`} className="contact-link">
            {contactData.email}
          </a>
        </p>
      </div>
    </div>
    </div>
</div>

<div className='col-12 col-md-4' >
              {Images.map((e) => {
                return (
                  <div className="box1">
                    <a
                      href="./"
                      className="d-flex justify-content-around align-items-center"
                    >
                      <div className="b1">
                        <img src={e.place} alt="img" />
                      </div>
                      <div className="b2">
                        {" "}
                        <a href="./">{e.text}</a>
                      </div>
                    </a>
                  </div>
                );
              })}

<div className=" rounded p-4 shadow-sm Uni_border" >
    <h4 className="pb-3 pt-3 lead fw-bold whit">Contact Information</h4>

    <div className="row ">
   
      <div className="col-12 mb-3">
        <div className="Small_border  rounded p-3 text-center ">
          <h4 className=" lead fw-semibold text-dark">Phone Numbers</h4>
          <i className="bi bi-telephone-fill fs-1 mb-2"></i>
          <div className=''>
            <a href="tel:+91 8421333435" className="d-block  whit">+91 8421333435</a>
            <a href="tel:+91 7875774499" className="d-block  whit">+91 7875774499</a>
            <a href="tel:+91 7620411277" className="d-block  whit">+91 7620411277</a>
            <a href="tel:+91 9284718314" className="d-block  whit">+91 9284718314</a>
          </div>
        </div>
      </div>

    
      <div className="col-12 mb-3 ">
        <div className="Small_border rounded p-3 text-center">
          <i className="bi bi-envelope fs-1 mb-2"></i>
          <div>
            <h4 className=" fw-semibold lead">Email</h4>
            <a href="mailto:booking@amrutatravel.com" className="whit d-block">
              booking@amrutatravel.com
            </a>
          </div>
        </div>
      </div>

      
      <div className="col-12 mb-3">
        <div className="Small_border  rounded p-3 text-center">
          <i className="bi bi-house-fill fs-1 mb-2"></i>
          <div>
            <h4 className=" fw-semibold lead">Address</h4>
            <p className="whit ">
              <i>
              Amruta Travels:<br />
              Office No.- 2, Ground Floor,<br />
              Wings H-(Puram), Prayeja City,<br />
              Phase-2, Near Prayeja City,<br />
              Vadgaon Budruk,<br />
              Pune, Maharashtra - 411041
              </i>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>

</div>
<FareCard/>

</div>
    </div>
</section>



        </div>
    );
}
export default MiniBus;