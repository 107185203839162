// import Slider from "../components/Slider";
import PricingSection from "./PricingSection";
import TeamSection from "./TeamSection";
import TestimonialSection from "./TestimonialSection";
import BlogSection from "./BlogSection";
import CtaSection from "./CtaSection";
import { Helmet } from 'react-helmet';
import OfferingsSection from "./OfferingsSection";
import SliderSection from "./SliderSection";
import LogoSlider from "./LogoSlider";
import RecentTrip from "./RecentTrip";
import Carousel from "../components/Carousel";
function Home() {


   
const schemaData = 
{
   "@context": "https://schema.org",
  "@type": "TravelAgency",
  "name": "Amruta Travels",
  "description": "Explore top-notch travel services with Amruta Travels. Book luxury mini buses, personalized tours, and travel packages for corporate events, family trips, and more in Pune and surrounding areas.",
  "address": {
    "@type": "PostalAddress",
    "streetAddress": "",
    "addressLocality": "Pune",
    "addressRegion": "MH",
    "postalCode": "411041",
    "addressCountry": "IN"
  },                                    "aggregateRating": {
    "@type": "AggregateRating",
    "bestRating": "5",
    "worstRating": "1",
    "ratingValue": "4.8",
    "ratingCount": "9000"
  },
  "telephone": "+91 8421333435",
  "image": "https://amrutatravel.com/img/OG-Image/OG-Image-01.jpg",
  "sameAs": [
    "https://https://www.facebook.com/people/Amruta-travels/61567052876581/?mibextid=LQQJ4d&rdid=ik0ebd0HKUH6Pi4Z&share_url=https%3A%2F%2Fwww.facebook.com%2Fshare%2F1ApFoorKKj%2F%3Fmibextid%3DLQQJ4d/amrutatravels",
    "https://https://www.instagram.com/amruta_travels_//amrutatravels",
    "https://www.linkedin.com/company/amrutatravels"
  ]
}



  return (
    
    <div>

<Helmet>
  <title> Tempo Traveller On Rent in Pune ₹25 Per Km | Contact: +91 8421333435 | Amruta Travels | Luxury Tempo Traveller Hire for Outstation & Local Trips</title>
  <meta name="description" content="Hire a Tempo Traveller in Pune for local or outstation trips. Affordable rates for 17 seater and luxury vehicles. Book your ride to Shirdi, Mahabaleshwar, Goa & more with Amruta Travels." />
  <meta name="keywords" content="Tempo traveller in Pune, 17 seater bus on rent in Pune price, 17 seater tempo traveller on rent in Pune, Traveller on rent Pune, Traveller bus on rent in Pune, Tempo Traveller on rent Pune to Outstation, Tempo traveller on rent in Pune, 17 seater tempo traveller on rent in Pune, Pune to Shirdi tempo traveller rate, Tempo Traveller hire in Pune, Tempo Traveller on rent in Hadapsar, Tempo Traveller on rent in Pune rate per km, Tempo Traveller Rental Pune, Pune to Mahabaleshwar cab tempo traveller, Pune to Goa tempo traveller on Rent, Luxury Tempo Traveller on rent in Pune" />
  <script type="application/ld+json">
    {`
      {
        "@context": "https://schema.org",
        "@type": "Service",
        "name": "Tempo Traveller On Rent in Pune",
        "description": "Affordable Tempo Traveller rentals for local and outstation trips in Pune. Choose from 17-seater to luxury Tempo Travellers for trips to Shirdi, Mahabaleshwar, Goa, and more. Book with Amruta Travels for a comfortable journey.",
        "address": {
          "@type": "PostalAddress",
          "streetAddress": "Office No.- 2, Ground Floor, Wings H-(Puram), Prayeja City, Phase-2, Near Prayeja City, Vadgaon Budruk",
          "addressLocality": "Pune",
          "addressRegion": "Maharashtra",
          "postalCode": "411041",
          "addressCountry": "IN"
        },
        "telephone": "+91-8421333435",
        "url": "https://amrutatravel.com/tempo-traveller-on-rent-in-pune",
        "logo": "https://amrutatravel.com/images/tempo-traveller-on-rent.jpg",
        "image": "https://amrutatravel.com/images/tempo-traveller-on-rent.jpg",
        "priceRange": "₹5000 - ₹25000",
        "offers": {
          "@type": "Offer",
          "url": "https://amrutatravel.com/tempo-traveller-on-rent-in-pune",
          "priceCurrency": "INR",
          "price": "7000",
          "priceValidUntil": "2024-12-31"
        },
        "aggregateRating": {
          "@type": "AggregateRating",
          "ratingValue": "4.8",
          "reviewCount": "9000"
        },
        
          
        "serviceArea": {
          "@type": "Place",
          "name": "Pune",
          "geo": {
            "@type": "GeoCoordinates",
            "latitude": "18.5204",
            "longitude": "73.8567"
          }
        },
        "availableChannel": {
          "@type": "ServiceChannel",
          "serviceUrl": "https://amrutatravel.com/tempo-traveller-on-rent-in-pune"
        },
        "keywords": "Tempo traveller in Pune, 17 seater bus on rent in Pune price, 17 seater tempo traveller on rent in Pune, Traveller on rent Pune, Traveller bus on rent in Pune, Tempo Traveller on rent Pune to Outstation, Tempo traveller on rent in Pune, 17 seater tempo traveller on rent in Pune, Pune to Shirdi tempo traveller rate, Tempo Traveller hire in Pune, Tempo Traveller on rent in Hadapsar, Tempo Traveller on rent in Pune rate per km, Tempo Traveller Rental Pune, Pune to Mahabaleshwar cab tempo traveller, Pune to Goa tempo traveller on Rent, Luxury Tempo Traveller on rent in Pune"
      }
    `}
  </script>
</Helmet>


    <Carousel/>
      <OfferingsSection />
      <CtaSection />
      <PricingSection />
      <TeamSection />
      <TestimonialSection />
      <BlogSection />
      <div className="py-md-3 my-md-5">
      <LogoSlider/>

      </div>

    </div>

  );
}
export default Home;